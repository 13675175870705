body {
  margin: 0;
  font-family: 'myriad-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #494948 !important;
}
canvas {
  height: 100vh;
  /* width: 100%; */
  display: block;
  position: absolute;
}

@font-face {
  font-family: 'myriad-pro';
  src: local('myriad-pro'), url(./fonts/MYRIADPRO.OTF) format('opentype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Link-ative {
  color: #00ced1;
  font-size: 40rem;
}

#root {
  background-color: white;
  min-height: 100vh;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: initial !important;
  overflow: visible !important;
  line-height: 20px !important;
  padding-top: 15px !important;
}

.MuiContainer-maxWidthLg {
  max-width: 1410px !important;
}

.MuiContainer-root {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.MuiListItemText-root {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.MuiListItem-gutters {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.makeStyles-contentContainer-4 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.MuiGrid-grid-xl-3 {
  max-width: 20% !important;
}

.MuiTypography-body1 {
  font-family: myriad-pro, sans-serif !important;
}

.MuiButton-root {
  font-family: myriad-pro, sans-serif !important;
}

.MuiMenuItem-dense {
  font-family: myriad-pro, sans-serif !important;
}

.MuiPaper-rounded {
  border-radius: 0px !important;
}

.MuiDataGrid-root {
  font-family: myriad-pro, sans-serif !important;
}

.MuiMenuItem-root {
  font-family: myriad-pro, sans-serif !important;
}

.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiImageListItemBar-subtitle {
  display: inline-grid !important;
}
